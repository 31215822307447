import { default as _3dsecurez8X4RQcSpOMeta } from "/app/pages/3dsecure.vue?macro=true";
import { default as _91slug_93evGKfLinVaMeta } from "/app/pages/akcii/[slug].vue?macro=true";
import { default as index44sjvJGqVIMeta } from "/app/pages/akcii/index.vue?macro=true";
import { default as appuxecjmUeNfMeta } from "/app/pages/app.vue?macro=true";
import { default as appredirectCYL303o1f1Meta } from "/app/pages/appredirect.vue?macro=true";
import { default as checkoutLpmDViNIqPMeta } from "/app/pages/checkout.vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as getappw4yPc2UofyMeta } from "/app/pages/getapp.vue?macro=true";
import { default as indextOXrPte1wtMeta } from "/app/pages/index/address/index.vue?macro=true";
import { default as loginxiNz8s4A5lMeta } from "/app/pages/index/auth/login.vue?macro=true";
import { default as profilercWxM2t3CnMeta } from "/app/pages/index/auth/profile.vue?macro=true";
import { default as registerBOjwqnCxuYMeta } from "/app/pages/index/auth/register.vue?macro=true";
import { default as indexvbIDjr5B6KMeta } from "/app/pages/index/cart/index.vue?macro=true";
import { default as _91id_93LSCwQGRJpxMeta } from "/app/pages/index/groups/[id].vue?macro=true";
import { default as _91id_93WLyA4WCuDMMeta } from "/app/pages/index/halves/[id].vue?macro=true";
import { default as _91id_936a8nKRYXLyMeta } from "/app/pages/index/products/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as ofertagbEXBNUbMLMeta } from "/app/pages/oferta.vue?macro=true";
import { default as _91id_93m5hwe6XdulMeta } from "/app/pages/orders/[id].vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as policyhDmBrXN4qOMeta } from "/app/pages/policy.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as rulesCVy0F1SzKVMeta } from "/app/pages/rules.vue?macro=true";
import { default as success_45orderdi6pzRbzTxMeta } from "/app/pages/success-order.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "3dsecure",
    path: "/3dsecure",
    component: () => import("/app/pages/3dsecure.vue").then(m => m.default || m)
  },
  {
    name: "akcii-slug",
    path: "/akcii/:slug()",
    component: () => import("/app/pages/akcii/[slug].vue").then(m => m.default || m)
  },
  {
    name: "akcii",
    path: "/akcii",
    component: () => import("/app/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/app/pages/app.vue").then(m => m.default || m)
  },
  {
    name: "appredirect",
    path: "/appredirect",
    meta: appredirectCYL303o1f1Meta || {},
    component: () => import("/app/pages/appredirect.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: checkoutLpmDViNIqPMeta || {},
    component: () => import("/app/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "getapp",
    path: "/getapp",
    meta: getappw4yPc2UofyMeta || {},
    component: () => import("/app/pages/getapp.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-address",
    path: "address",
    component: () => import("/app/pages/index/address/index.vue").then(m => m.default || m)
  },
  {
    name: "index-auth-login",
    path: "auth/login",
    meta: loginxiNz8s4A5lMeta || {},
    component: () => import("/app/pages/index/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "index-auth-profile",
    path: "auth/profile",
    meta: profilercWxM2t3CnMeta || {},
    component: () => import("/app/pages/index/auth/profile.vue").then(m => m.default || m)
  },
  {
    name: "index-auth-register",
    path: "auth/register",
    component: () => import("/app/pages/index/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "index-cart",
    path: "cart",
    component: () => import("/app/pages/index/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "index-groups-id",
    path: "groups/:id()",
    component: () => import("/app/pages/index/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "index-halves-id",
    path: "halves/:id()",
    component: () => import("/app/pages/index/halves/[id].vue").then(m => m.default || m)
  },
  {
    name: "index-products-id",
    path: "products/:id()",
    component: () => import("/app/pages/index/products/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "oferta",
    path: "/oferta",
    meta: ofertagbEXBNUbMLMeta || {},
    component: () => import("/app/pages/oferta.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93m5hwe6XdulMeta || {},
    component: () => import("/app/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyhDmBrXN4qOMeta || {},
    component: () => import("/app/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "rules",
    path: "/rules",
    meta: rulesCVy0F1SzKVMeta || {},
    component: () => import("/app/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "success-order",
    path: "/success-order",
    component: () => import("/app/pages/success-order.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/:city/auth/profile",
    component: component_45stubnO9MU04yTU
  }
]